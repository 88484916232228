<template>
  <div>
    <article class="account" v-if="success === true">
      <h2 class="account__heading">Account Confirmed</h2>
      <section class="account__content post-content confirmMessage">
        <p>Your account has been confirmed and a password has been sent to {{ email }}.</p>
        <p>
          You will be redirected to next step in {{ countDown.seconds }} seconds.<br />
          <router-link :to="{ name: 'supplierEdit', params: { id: this.id }, query: { guide: true } }"
            >Go to next step</router-link
          >
        </p>
      </section>
    </article>
    <article class="account" v-if="success === false">
      <h2 class="account__heading">Confirmation error</h2>
      <section class="account__content post-content confirmMessage">
        <p>Something went wrong</p>
      </section>
    </article>
    <div class="loader" :class="{ 'is-visible': loading }"></div>
  </div>
</template>

<script>
import countDown from './../../lib/timers';

export default {
  data() {
    return {
      loading: true,
      success: null,
      countDown: {},
      email: null,
      id: null,
    };
  },
  async mounted() {
    let { guid } = this.$route.query;

    try {
      const { success, email, id } = await this.$store.user.confirmSupplierEmailAndCreateUser(guid);

      this.loading = false;
      this.success = success;
      this.email = email;
      this.id = id;

      if (success) {
        this.countDown = countDown(this, 4, () => {
          this.$router.push({
            name: 'supplierEdit',
            params: {
              id: id,
            },
            query: {
              guide: true,
            },
          });
        });
      }
    } catch (error) {
      this.loading = false;
      this.success = false;
      console.log(error);
    }
  },
};
</script>

<style lang="scss">
@use 'sass:math';

.confirmMessage {
  text-align: center;
}
</style>
